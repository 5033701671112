"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleDialPhoneNumberClickedOnJOContact = exports.twilioFindTransferByUserCallSid = exports.doesTransferExistForUserCallSid = exports.startOutgoingCall = exports.fetchCallStatus = exports.setDurationEventLocal = exports.setDurationEventRemote = exports.twilioTransferDecline = exports.twilioTransferComplete = exports.twilioTransferInitiate = exports.getTimestampActionItemSavedDurationEventInfo = exports.getTimestampUnholdDurationEventInfo = exports.getTimestampHoldDurationEventInfo = exports.getTimestampCallEndDurationEventInfo = exports.getTimestampCallStartDurationEventInfo = exports.twilioEndCall = exports.calculateAndApplyCallDuration = exports.setupTwilioDeviceHandlers = exports.initializeTwilio = void 0;
const outbound_queue_item_utils_1 = require("../ui/elements/jo-call-screen/utilities/outbound-queue-item-utils");
const mutations_1 = require("../ui/elements/jo-outbound/jo-outbound-table/mutations");
const graphql_1 = require("../services/graphql");
const twilio_client_1 = require("../services/twilio-client");
const dates_and_times_1 = require("../utilities/dates-and-times/dates-and-times");
const mapped_env_variables_1 = require("../utilities/environment/mapped-env-variables");
const error_handling_1 = require("../utilities/error-handling");
const user_status_event_mutations_1 = require("../utilities/logging-and-monitoring/user-status-event-mutations");
const update_next_attempt_to_be_made_at_1 = require("../utilities/outbound-queue-items/update-next-attempt-to-be-made-at");
const update_outbound_queue_item_status_1 = require("../utilities/outbound-queue-items/update-outbound-queue-item-status");
const pause_1 = require("../utilities/pause");
const is_valid_phone_number_1 = require("../utilities/phone-number/is-valid-phone-number");
const constants_1 = require("./constants");
const add_data_to_error_1 = require("./logging/error-handling/add-data-to-error");
const store_1 = require("./store");
const utilities_1 = require("./utilities");
const helper_utils_for_profile_settings_1 = require("../ui/main-pages/profile-settings-page/helper-utils-for-profile-settings");
const ring_tone_options_1 = require("../ui/main-pages/profile-settings-page/ring-tone-options");
async function initializeTwilio() {
    if (store_1.GlobalStore.authenticatedUser === 'NOT_SET') {
        return;
    }
    try {
        const twilioAccessToken = await getTwilioAccessToken();
        const twilioDevice = await createTwilioDevice(twilioAccessToken, store_1.GlobalStore.authenticatedUser);
        store_1.GlobalStore.twilioDevice = twilioDevice;
        setupTwilioDeviceHandlers(store_1.GlobalStore.twilioDevice);
    }
    catch (error) {
        (0, utilities_1.handleError)(error);
    }
}
exports.initializeTwilio = initializeTwilio;
async function getTwilioAccessToken() {
    const twilioAccessTokenResponse = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            twilioAccessToken
        }
    `);
    if (twilioAccessTokenResponse.data === null || twilioAccessTokenResponse.data === undefined) {
        throw new Error(`Could not retrieve the twilio access token`);
    }
    const twilioAccessToken = twilioAccessTokenResponse.data.twilioAccessToken;
    return twilioAccessToken;
}
async function createTwilioDevice(twilioAccessToken, authenticatedUser) {
    const twilioDevice = new twilio_client_1.Twilio.Device();
    const userSettings = await (0, helper_utils_for_profile_settings_1.fetchUserSettings)(authenticatedUser.id);
    return twilioDeviceSetUp(twilioDevice, twilioAccessToken, userSettings?.selected_ring_tone);
}
function twilioDeviceSetUp(twilioDevice, twilioAccessToken, ringtone) {
    let options = { allowIncomingWhileBusy: false };
    /**
     * Documentation for future reference
     * https://www.twilio.com/docs/voice/sdks/javascript/overview-1x-deprecated/device#available-sound-properties
     */
    if (ringtone && ringtone !== ring_tone_options_1.ringtones.default.Default) {
        options = {
            ...options,
            sounds: {
                incoming: ringtone,
            },
        };
    }
    twilioDevice.setup(twilioAccessToken, options);
    return twilioDevice;
}
async function setupTwilioDeviceHandlers(twilioDevice) {
    twilioDevice.on('ready', twilioDevice => {
        /** This log is useful for seeing if the twilio device recovered after an error. */
        console.info('Twilio device ready', twilioDevice);
    });
    twilioDevice.on('error', error => {
        /**
         * Do not send these errors to Sentry or show any pop-ups to the use. The Twilio client
         * consistently errors _all the time_ but it simply recovers afterwards.
         *
         * Example error case: it throws errors when hitting the "back" button from a different
         * website back to our website.
         *
         * Are these errors due to race conditions in our own code? That's very possible.
         * Regardless, when this error handler is hit, there's nothing to do, so there's no point in
         * sending it anywhere. We'll log it here anyway just in case something crazy really does
         * happen.
         */
        console.info(`Twilio device error`, error);
    });
    twilioDevice.on('incoming', async (connection) => {
        (0, utilities_1.assertIsSet)(store_1.GlobalStore.authenticatedUser);
        if (store_1.GlobalStore.incomingTwilioConnection !== undefined) {
            // if there is already an incoming call, the new incoming call will be sent back to the queue
            connection.reject();
            return;
        }
        store_1.GlobalStore.incomingTwilioConnection = connection;
        // This gives some time in case the jill happened to go on break or lunch at the exact moment a call was sent to him/her
        await (0, pause_1.pause)(500);
        const workStatus = await fetchWorkStatus(store_1.GlobalStore.authenticatedUser.id);
        if (workStatus !== 'CLOCKED_IN') {
            connection.reject();
            return;
        }
        closeOutgoingCallGlobalSubscriptionsIfNecessary();
        const rawCallId = new window.URLSearchParams(connection.parameters.Params).get('callId');
        if (rawCallId === null) {
            const message = 'The call id was not sent with the connection';
            alert(message);
            throw new Error(message);
        }
        const callId = parseInt(rawCallId);
        const now = new Date();
        (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: store_1.GlobalStore.authenticatedUser.id,
            eventType: 'INCOMING_CALL_RECEIVED_BY_USER',
            timestamp: now,
        });
        // TODO instead of doing this network request here, we should have the action item id passed up in the connection params
        // TODO that might require some backend changes including changing the database
        const call = await fetchCallForIncoming(callId);
        const actionItemId = call.action_item?.id || 'NOT_SET';
        store_1.GlobalStore.dispatch({
            type: 'RESET_CALL_STATE',
        });
        store_1.GlobalStore.currentCallInformation = {
            ...store_1.GlobalStore.currentCallInformation,
            callAnswered: false,
            callIncoming: true,
            canInitiateNewTransfer: false,
            direction: 'INCOMING',
            incomingCallType: new window.URLSearchParams(connection.parameters.Params).get('incomingCallType') || 'NOT_SET',
            incomingConnection: connection,
            jillCallSid: connection.parameters.CallSid || 'NOT_SET',
            returnPath: `${window.location.pathname}${window.location.search}`,
            userCallSid: new window.URLSearchParams(connection.parameters.Params).get('userCallSid') ||
                'NOT_SET',
            userCallStatus: 'NOT_SET',
        };
        const callScreenMode = 'INCOMING_NEW_ACTION_ITEM';
        (0, utilities_1.navigate)(`/action-item?actionItemId=${actionItemId}&callId=${call.id}&companyId=${call.company?.id}&callScreenMode=${callScreenMode}`);
    });
    twilioDevice.on('connect', async (connection) => {
        closeOutgoingCallGlobalSubscriptionsIfNecessary();
        // this as cast is necessary because we're accessing a private member, yikes
        const connectionMessage = connection
            .message;
        if (connectionMessage.incomingCallType === 'CALLBACK') {
            setJoCallScreenAsRedirecting();
            setTimeout(async () => {
                connection.disconnect();
                const callId = parseInt(connectionMessage.callId);
                const call = await fetchCallForIncomingCallback(callId);
                const actionItemId = undefined;
                const contactId = call.contact?.id;
                const companyId = call.company?.id;
                const phoneNumber = call.origin_phone;
                (0, utilities_1.assertIsSet)(store_1.GlobalStore.authenticatedUser, utilities_1.handleError, 'GlobalStore.authenticatedUser');
                const options = {
                    actionItemId,
                    contactId,
                    companyId,
                    incomingActionItemId: call.action_item?.id,
                    phoneNumber,
                    outgoingCallType: 'CALLBACK',
                    jillId: store_1.GlobalStore.authenticatedUser.id,
                    direction: 'OUTGOING', // TODO do we need this?
                };
                twilioDevice.connect(options);
            }, 2000);
            return;
        }
        if (connection.direction === 'OUTGOING') {
            if (store_1.GlobalStore.authenticatedUser === 'NOT_SET') {
                (0, utilities_1.handleError)(`outgoing call: user is not set: ${store_1.GlobalStore}`);
                throw new Error(`outgoing call: user is not set: ${store_1.GlobalStore}`);
            }
            const joCallScreen = document.getElementById('jo-call-screen');
            if (joCallScreen) {
                joCallScreen.store.redirectingIncomingCallbackToOutgoing = true;
            }
            const jillCallSid = connection.parameters.CallSid;
            await subscribeToOutgoingCallInitiatedAndContactCallStatus(store_1.GlobalStore.authenticatedUser.id);
            const call = await fetchCallForOutgoing(jillCallSid);
            if (!call) {
                (0, add_data_to_error_1.addErrorDataAndThrow)(new Error(`outgoing call is undefined`), {
                    extraData: {
                        call,
                        jillCallSid,
                    },
                });
            }
            createCallbackInitiatedDurationEventIfNecessary(connection, store_1.GlobalStore.authenticatedUser.id, call.id);
            const actionItem = call.action_item;
            const actionItemId = actionItem?.id ?? 'NOT_SET';
            store_1.GlobalStore.dispatch({
                type: 'RESET_CALL_STATE',
            });
            store_1.GlobalStore.currentCallInformation = {
                ...store_1.GlobalStore.currentCallInformation,
                callIncoming: false,
                incomingConnection: connection,
                userCallSid: 'NOT_SET',
                jillCallSid: connection.parameters.CallSid || 'NOT_SET',
                direction: 'OUTGOING',
                incomingCallType: 'USER',
                returnPath: store_1.GlobalStore.route.pathname === '/action-item'
                    ? '/'
                    : `${window.location.pathname}${window.location.search}`,
            };
            const callScreenMode = getCallScreenModeForOutgoingCall(actionItem);
            const outboundAttemptId = getOutboundAttemptId(connection);
            updateOutboundQueueItemAndAttemptIfNecessary(actionItemId, outboundAttemptId, call.id);
            (0, utilities_1.navigate)(`/action-item?actionItemId=${actionItemId}&callScreenMode=${callScreenMode}&callId=${call.id}&companyId=${call.company?.id}&outboundAttemptId=${outboundAttemptId}`);
            if (joCallScreen) {
                joCallScreen.store.redirectingIncomingCallbackToOutgoing = false;
            }
        }
    });
    twilioDevice.on('disconnect', async (connection) => {
        store_1.GlobalStore.incomingTwilioConnection = undefined;
        closeOutgoingCallGlobalSubscriptionsIfNecessary();
    });
    twilioDevice.on('offline', async (twilioDevice) => {
        const twilioAccessToken = await getTwilioAccessToken();
        twilioDeviceSetUp(twilioDevice, twilioAccessToken);
    });
    twilioDevice.on('cancel', connection => {
        store_1.GlobalStore.incomingTwilioConnection = undefined;
        (0, utilities_1.assertIsSet)(store_1.GlobalStore.authenticatedUser);
        const now = new Date();
        (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: store_1.GlobalStore.authenticatedUser.id,
            eventType: 'INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED',
            timestamp: now,
        });
        store_1.GlobalStore.dispatch({
            type: 'RESET_CALL_STATE',
        });
        closeOutgoingCallGlobalSubscriptionsIfNecessary();
        // TODO for some reason, this cancel event happens twice, so history.back() twice will actually take us back to the call screen
        // window.history.back();
        (0, utilities_1.navigate)(store_1.GlobalStore.route.previousFullPath);
    });
}
exports.setupTwilioDeviceHandlers = setupTwilioDeviceHandlers;
/**
 * Technically this code should never actually end up running multiple times and thus shouldn't need
 * to be gated behind an "if" like this. However, our frontend code base somehow manages to force
 * the browser to load the same file multiple times. The only constant between them will be the
 * global window so this is hooking into that.
 */
if (!globalThis.twilioInterval) {
    globalThis.twilioInterval = setInterval(async () => {
        const localDurationEventInfosResult = getLocalDurationEventInfos();
        if (localDurationEventInfosResult === 'NOT_FOUND') {
            return;
        }
        const remoteDurationEventFoundResults = await getRemoteDurationEventFoundResults(localDurationEventInfosResult);
        await createRemoteDurationEvents(localDurationEventInfosResult, remoteDurationEventFoundResults);
        const notFoundLocalDurationEventInfos = getNotFoundLocalDurationEventInfos(localDurationEventInfosResult, remoteDurationEventFoundResults);
        setLocalDurationEventInfos(notFoundLocalDurationEventInfos);
    }, 30 * dates_and_times_1.oneSecondInMilliseconds);
}
async function updateOutboundQueueItemAndAttemptIfNecessary(actionItemId, outboundAttemptId, callId) {
    if (actionItemId !== 'NOT_SET' && outboundAttemptId !== 'NOT_SET') {
        await (0, mutations_1.updateOutboundAttempt)({
            outboundAttemptId: outboundAttemptId,
            actionItemId: actionItemId,
            callId,
        });
        await updateOutboundQueueItem(outboundAttemptId);
    }
}
async function updateOutboundQueueItem(outboundAttemptId) {
    const queueItem = await (0, outbound_queue_item_utils_1.getOutboundQueueItemBasedOnAttemptId)(outboundAttemptId);
    await (0, update_next_attempt_to_be_made_at_1.updateOutboundQueueItemNextAttemptToBeMadeAt)(queueItem);
    await (0, update_outbound_queue_item_status_1.updateOutboundQueueItemStatus)(queueItem);
}
function getOutboundAttemptId(connection) {
    const rawOutboundAttemptId = connection.customParameters.get('outboundAttemptId');
    if (!rawOutboundAttemptId || rawOutboundAttemptId === 'undefined') {
        return 'NOT_SET';
    }
    return rawOutboundAttemptId;
}
async function fetchCallForOutgoing(jillCallSid) {
    const callResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($callSid: String!) {
                Calls__Outgoing: findCalls(filter: {twilio_parent_call_sid: {eq: $callSid}}) {
                    items {
                        id
                        action_item {
                            id
                            call {
                                id
                            }
                            created_at
                            updated_at
                        }
                        company {
                            id
                        }
                    }
                }
            }
        `, {
        callSid: jillCallSid,
    });
    if (callResult.succeeded === false) {
        throw new Error(`fetchCallForOutgoing failed for callSid: ${jillCallSid}`);
    }
    const call = callResult.value.data.Calls__Outgoing.items[0];
    return call;
}
async function fetchCallForIncomingCallback(callId) {
    const callResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($callId: Int!) {
                Calls__Callback: getCalls(id: $callId) {
                    id
                    action_item {
                        id
                    }
                    company {
                        id
                        pod {
                            name
                        }
                    }
                    contact {
                        id
                    }
                    origin_phone
                }
            }
        `, {
        callId,
    });
    if (callResult.succeeded === false) {
        (0, utilities_1.handleError)(`fetchCallForIncomingCallback failed for callId: ${callId}`);
        throw new Error(`fetchCallForIncomingCallback failed for callId: ${callId}`);
    }
    const call = callResult.value.data.Calls__Callback;
    return call;
}
async function fetchCallForIncoming(callId) {
    const callResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($callId: Int!) {
                Calls__TwilioIncoming: getCalls(id: $callId) {
                    id
                    action_item {
                        id
                    }
                    company {
                        id
                    }
                }
            }
        `, {
        callId,
    });
    if (callResult.succeeded === false) {
        (0, utilities_1.handleError)(`fetchCallForIncoming failed for callId: ${callId}`);
        throw new Error(`fetchCallForIncoming failed for callId: ${callId}`);
    }
    const call = callResult.value.data.Calls__TwilioIncoming;
    return call;
}
function setJoCallScreenAsRedirecting() {
    const joCallScreen = document.getElementById('jo-call-screen');
    if (joCallScreen !== null && joCallScreen !== undefined) {
        joCallScreen.store.redirectingIncomingCallbackToOutgoing = true;
    }
}
function createCallbackInitiatedDurationEventIfNecessary(twilioClientConnection, userId, callId) {
    if (twilioClientConnection.customParameters.get('outgoingCallType') === 'CALLBACK') {
        createCallBackInitiatedEvent(userId, callId, new Date());
    }
}
async function createCallBackInitiatedEvent(userId, callId, date) {
    if (userId === 'NOT_SET' || callId === 'NOT_SET') {
        return;
    }
    const CallBackDurationEventInfo = {
        description: 'CALLBACK_INITIATED',
        type: 'INFO',
        timestamp: date.toISOString(),
        userId: userId,
        callId: callId,
    };
    setDurationEventLocal(CallBackDurationEventInfo);
    await setDurationEventRemote(CallBackDurationEventInfo);
}
function getNotFoundLocalDurationEventInfos(durationEventInfos, remoteDurationEventFoundResults) {
    const notFoundLocalDurationEventInfos = durationEventInfos.filter((durationEventInfo, index) => {
        return remoteDurationEventFoundResults[index] === 'NOT_FOUND';
    });
    return notFoundLocalDurationEventInfos;
}
function getLocalDurationEventInfos() {
    const localDurationEventInfosResult = window.localStorage.getItem(constants_1.LOCAL_DURATION_EVENT_INFOS);
    if (localDurationEventInfosResult === null) {
        return 'NOT_FOUND';
    }
    else {
        return JSON.parse(localDurationEventInfosResult);
    }
}
function setLocalDurationEventInfos(durationEventInfos) {
    window.localStorage.setItem(constants_1.LOCAL_DURATION_EVENT_INFOS, JSON.stringify(durationEventInfos));
}
async function getRemoteDurationEventFoundResults(durationEventInfos) {
    return await Promise.all(durationEventInfos.map(async (durationEventInfo) => {
        const remoteDurationEventFoundResult = await fetchRemoteDurationEventFound(durationEventInfo);
        return remoteDurationEventFoundResult;
    }));
}
async function createRemoteDurationEvents(durationEventInfos, remoteDurationEventFoundResults) {
    for (let i = 0; i < remoteDurationEventFoundResults.length; i++) {
        const result = remoteDurationEventFoundResults[i];
        if (result == null) {
            throw new Error('result is null');
        }
        const remoteDurationEventFoundResult = result;
        const durationEventInfo = durationEventInfos[i];
        if (durationEventInfo == null) {
            throw new Error('durationEventInfo is null');
        }
        if (remoteDurationEventFoundResult === 'NOT_FOUND') {
            // TODO do we want to mark the duration event as created later on, outside of the call?
            await setDurationEventRemote(durationEventInfo);
        }
    }
}
async function fetchRemoteDurationEventFound(durationEventInfo) {
    const response = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($type: String!, $description: String!, $timestamp: DateTime!, $callId: Int!) {
                findDuration_events(
                    filter: {
                        and: [
                            {timestamp: {eq: $timestamp}}
                            {call_id: {eq: $callId}}
                            {type: {eq: $type}}
                            {description: {eq: $description}}
                        ]
                    }
                ) {
                    items {
                        id
                    }
                }
            }
        `, {
        type: durationEventInfo.type,
        description: durationEventInfo.description,
        timestamp: durationEventInfo.timestamp,
        callId: durationEventInfo.callId,
    });
    if (response.data.findDuration_events.items.length === 0) {
        return 'NOT_FOUND';
    }
    else {
        return 'FOUND';
    }
}
async function calculateAndApplyCallDuration(callId, wasPersonalAdminCall) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($callId: Int!, $wasPersonalAdminCall: Boolean!) {
                    calculateAndApplyCallDuration(
                        callId: $callId
                        wasPersonalAdminCall: $wasPersonalAdminCall
                    )
                }
            `, {
            callId,
            wasPersonalAdminCall,
        });
    }
    catch (error) {
        throw new Error(`calculateAndApplyCallDuration error: ${error}`);
    }
}
exports.calculateAndApplyCallDuration = calculateAndApplyCallDuration;
async function twilioEndCall(callId, externalTransferSent, incomingCallType, internalTransferSent, jillCallSid, jillId, userCallSid) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $callId: Int!
                    $externalTransferSent: Boolean!
                    $incomingCallType: INCOMING_CALL_TYPE!
                    $internalTransferSent: Boolean!
                    $jillCallSid: String!
                    $jillId: Int!
                    $userCallSid: String!
                ) {
                    endCall(
                        callId: $callId
                        userCallSid: $userCallSid
                        jillCallSid: $jillCallSid
                        jillId: $jillId
                        internalTransferSent: $internalTransferSent
                        externalTransferSent: $externalTransferSent
                        incomingCallType: $incomingCallType
                    )
                }
            `, {
            callId,
            userCallSid,
            jillCallSid,
            jillId,
            internalTransferSent,
            externalTransferSent,
            incomingCallType,
        });
        if (result.succeeded === false) {
            return result;
        }
        else {
            return { succeeded: true };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('twilioEndCall', error);
    }
}
exports.twilioEndCall = twilioEndCall;
function getTimestampCallStartDurationEventInfo(timestamp, userId, callId) {
    const durationEventInfo = {
        type: 'START',
        description: 'CALL_START',
        timestamp: timestamp.toISOString(),
        userId,
        callId,
    };
    return durationEventInfo;
}
exports.getTimestampCallStartDurationEventInfo = getTimestampCallStartDurationEventInfo;
function getTimestampCallEndDurationEventInfo(timestamp, userId, callId) {
    const durationEventInfo = {
        type: 'INFO',
        description: 'CALL_END',
        timestamp: timestamp.toISOString(),
        userId,
        callId,
    };
    return durationEventInfo;
}
exports.getTimestampCallEndDurationEventInfo = getTimestampCallEndDurationEventInfo;
function getTimestampHoldDurationEventInfo(timestamp, userId, callId) {
    const durationEventInfo = {
        type: 'STOP',
        description: 'HOLD',
        timestamp: timestamp.toISOString(),
        userId,
        callId,
    };
    return durationEventInfo;
}
exports.getTimestampHoldDurationEventInfo = getTimestampHoldDurationEventInfo;
function getTimestampUnholdDurationEventInfo(timestamp, userId, callId) {
    const durationEventInfo = {
        type: 'START',
        description: 'UNHOLD',
        timestamp: timestamp.toISOString(),
        userId,
        callId,
    };
    return durationEventInfo;
}
exports.getTimestampUnholdDurationEventInfo = getTimestampUnholdDurationEventInfo;
function getTimestampActionItemSavedDurationEventInfo(timestamp, userId, callId) {
    const durationEventInfo = {
        type: 'STOP',
        description: 'ACTION_ITEM_SAVED',
        timestamp: timestamp.toISOString(),
        userId,
        callId,
    };
    return durationEventInfo;
}
exports.getTimestampActionItemSavedDurationEventInfo = getTimestampActionItemSavedDurationEventInfo;
async function twilioTransferInitiate(params) {
    try {
        const doesTransferExistResult = await doesTransferExistForUserCallSid(params.userCallSid);
        if (doesTransferExistResult.succeeded === false) {
            return doesTransferExistResult;
        }
        if (doesTransferExistResult.value === false) {
            const createNewTransferResult = await createNewTransfer(params);
            if (createNewTransferResult.succeeded === false) {
                return createNewTransferResult;
            }
        }
        const initiateTransferAttemptTransferAction = {
            type: 'INITIATE_TRANSFER_ATTEMPT',
            callId: params.callId,
            userCallSid: params.userCallSid,
            jillId: params.jillId,
            jillCallSid: params.jillCallSid,
            to: params.to,
            transferType: params.to.startsWith('client:')
                ? 'INTERNAL_TRANSFER'
                : 'EXTERNAL_TRANSFER',
            twilioPhoneNumber: params.twilioPhoneNumber,
            verifiedBusinessNumber: params.verifiedBusinessNumber,
        };
        const initiateTransferResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($initiateTransferAttemptTransferAction: TransferAction!) {
                    dispatchTransferAction(action: $initiateTransferAttemptTransferAction)
                }
            `, {
            initiateTransferAttemptTransferAction,
        });
        if (initiateTransferResult.succeeded === false) {
            return initiateTransferResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('twilioTransferInitiate', error);
    }
}
exports.twilioTransferInitiate = twilioTransferInitiate;
async function createNewTransfer(params) {
    try {
        const createTransferTransferAction = {
            type: 'CREATE_TRANSFER',
            callId: params.callId,
            jillId: params.jillId,
            jillCallSid: params.jillCallSid,
            originalCallDirection: params.originalCallDirection,
            to: params.to,
            twilioPhoneNumber: params.twilioPhoneNumber,
            userCallSid: params.userCallSid,
            verifiedBusinessNumber: params.verifiedBusinessNumber,
        };
        const createTransferResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($createTransferTransferAction: TransferAction!) {
                    dispatchTransferAction(action: $createTransferTransferAction)
                }
            `, {
            createTransferTransferAction,
        });
        if (createTransferResult.succeeded === false) {
            return createTransferResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createNewTransfer', error);
    }
}
async function twilioTransferComplete(transferId, userCallSid) {
    try {
        const transferAction = {
            type: 'COMPLETE_TRANSFER',
            transferId,
            userCallSid,
        };
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($transferAction: TransferAction!) {
                    dispatchTransferAction(action: $transferAction)
                }
            `, {
            transferAction,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        else {
            return { succeeded: true };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('twilioTransferComplete', error);
    }
}
exports.twilioTransferComplete = twilioTransferComplete;
async function twilioTransferDecline(transferId, userCallSid) {
    try {
        const transferAction = {
            type: 'DECLINE_TRANSFER',
            transferId,
            userCallSid,
        };
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($transferAction: TransferAction!) {
                    dispatchTransferAction(action: $transferAction)
                }
            `, {
            transferAction,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        else {
            return { succeeded: true };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('twilioTransferDecline', error);
    }
}
exports.twilioTransferDecline = twilioTransferDecline;
async function setDurationEventRemote(durationEventInfo) {
    try {
        const remoteDurationEventFound = await fetchRemoteDurationEventFound(durationEventInfo);
        const timestamp = new Date().toISOString();
        if (remoteDurationEventFound === 'NOT_FOUND') {
            const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation (
                        $type: DURATION_EVENT_TYPE!
                        $description: DURATION_EVENT_DESCRIPTION!
                        $timestamp: DateTime!
                        $createdAt: DateTime!
                        $updatedAt: DateTime!
                        $userId: Int!
                        $callId: Int!
                    ) {
                        createDuration_events(
                            input: {
                                type: $type
                                description: $description
                                timestamp: $timestamp
                                created_at: $createdAt
                                updated_at: $updatedAt
                                user: {connect: {id: $userId}}
                                call: {connect: {id: $callId}}
                                valid: true
                            }
                        ) {
                            id
                        }
                    }
                `, {
                type: durationEventInfo.type,
                description: durationEventInfo.description,
                timestamp: durationEventInfo.timestamp,
                createdAt: timestamp,
                updatedAt: timestamp,
                userId: durationEventInfo.userId,
                callId: durationEventInfo.callId,
            });
        }
    }
    catch (error) {
        (0, utilities_1.handleError)(`setDurationEventRemote error: ${error}, ${JSON.stringify(durationEventInfo)}`);
    }
}
exports.setDurationEventRemote = setDurationEventRemote;
function setDurationEventLocal(durationEventInfo) {
    try {
        const localDurationEventInfosResult = getLocalDurationEventInfos();
        if (localDurationEventInfosResult === 'NOT_FOUND') {
            setLocalDurationEventInfos([durationEventInfo]);
        }
        else {
            const localDurationEventInfoAlreadyExists = localDurationEventInfosResult.find((localDurationEventInfo) => {
                return (durationEventInfo.callId === localDurationEventInfo.callId &&
                    durationEventInfo.description === localDurationEventInfo.description &&
                    (durationEventInfo.timestamp === localDurationEventInfo.timestamp ||
                        durationEventInfo.description === 'CALLBACK_INITIATED' ||
                        durationEventInfo.description === 'CALL_START' ||
                        durationEventInfo.description === 'CALL_END' ||
                        durationEventInfo.description === 'ACTION_ITEM_SAVED') &&
                    durationEventInfo.type === localDurationEventInfo.type &&
                    durationEventInfo.userId === localDurationEventInfo.userId);
            }) === undefined
                ? false
                : true;
            if (localDurationEventInfoAlreadyExists === false) {
                setLocalDurationEventInfos([
                    ...localDurationEventInfosResult,
                    durationEventInfo,
                ]);
            }
        }
    }
    catch (error) {
        (0, utilities_1.handleError)(`setDurationEventLocal error: ${error}`);
    }
}
exports.setDurationEventLocal = setDurationEventLocal;
async function fetchCallStatus(callSid) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($callSid: String!) {
                    callStatus(callSid: $callSid)
                }
            `, {
            callSid,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const callStatus = gqlResult.value.data.callStatus;
        return {
            succeeded: true,
            value: callStatus,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCallStatus', error);
    }
}
exports.fetchCallStatus = fetchCallStatus;
function startOutgoingCall(actionItemId, contactId, companyId, phoneNumber, jillId, outboundAttemptId) {
    try {
        const e164PhoneNumber = (0, utilities_1.formatPhoneNumberToE164)(phoneNumber);
        const isPhoneValid = (0, is_valid_phone_number_1.isValidPhoneNumber)(e164PhoneNumber);
        if (!isPhoneValid) {
            const errorMessage = 'Cannot create an outgoing call for an invalid phone number.';
            (0, utilities_1.joAlert)('Invalid Phone Number', errorMessage);
            (0, add_data_to_error_1.addErrorDataAndThrow)(new Error(errorMessage), {
                extraData: { e164PhoneNumber, phoneNumber },
            });
            return;
        }
        const options = {
            actionItemId,
            contactId,
            companyId,
            phoneNumber: e164PhoneNumber,
            outgoingCallType: 'OUTGOING',
            jillId,
            direction: 'OUTGOING',
            outboundAttemptId,
        };
        if (store_1.GlobalStore.twilioDevice === 'NOT_SET') {
            // TODO not sure what to do in this case, probably jo alert and such, probably best handled in caller
        }
        else {
            store_1.GlobalStore.twilioDevice.connect(options);
        }
    }
    catch (error) {
        (0, utilities_1.handleError)(`startOutgoingCall error: ${error}`);
    }
}
exports.startOutgoingCall = startOutgoingCall;
async function doesTransferExistForUserCallSid(userCallSid) {
    try {
        const findTransferResult = await twilioFindTransferByUserCallSid(userCallSid);
        if (findTransferResult.succeeded === false) {
            return findTransferResult;
        }
        if (findTransferResult.value === 'NOT_FOUND') {
            return {
                succeeded: true,
                value: false,
            };
        }
        else {
            return {
                succeeded: true,
                value: true,
            };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('doesTransferExistForUserCallSid', error);
    }
}
exports.doesTransferExistForUserCallSid = doesTransferExistForUserCallSid;
async function twilioFindTransferByUserCallSid(userCallSid) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($userCallSid: String!) {
                    Transfers__twilioFindTransferByUserCallSid: findTransfers(
                        filter: {user_call_sid: {eq: $userCallSid}}
                    ) {
                        items {
                            id
                        }
                    }
                }
            `, {
            userCallSid,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const transfer = gqlResult.value.data.Transfers__twilioFindTransferByUserCallSid.items[0];
        if (transfer === undefined) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: transfer,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('twilioFindTransferByUserCallSid', error);
    }
}
exports.twilioFindTransferByUserCallSid = twilioFindTransferByUserCallSid;
async function subscribeToOutgoingCallInitiatedAndContactCallStatus(userId) {
    try {
        const outgoingCallInitiatedSubscription = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription ($userId: Int!) {
                    outgoingCallInitiated(userId: $userId) {
                        callId
                        conferenceSid
                        contactCallSid
                    }
                }
            `, async (data) => {
            const callInitiatedResult = data.payload.data
                .outgoingCallInitiated;
            store_1.GlobalStore.currentCallInformation = {
                ...store_1.GlobalStore.currentCallInformation,
                userCallSid: callInitiatedResult.contactCallSid,
            };
            await subscribeToOutgoingUserCallStatusCallback(callInitiatedResult.contactCallSid);
            closeoutgoingCallInitiatedSubscriptionIfNecessary();
        }, {
            userId,
        });
        store_1.GlobalStore.subscriptions = {
            ...store_1.GlobalStore.subscriptions,
            outgoingCallInitiatedSubscription: outgoingCallInitiatedSubscription ?? 'NOT_SET',
        };
    }
    catch (error) {
        (0, utilities_1.handleError)(`subscribeToOutgoingCallInitiatedAndContactCallStatus error: ${error}`);
    }
}
async function subscribeToOutgoingUserCallStatusCallback(userCallSid) {
    try {
        const outgoingUserCallStatusSubscription = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription ($userCallSid: String!) {
                    userCallStatusCallback(userCallSid: $userCallSid)
                }
            `, data => {
            const userCallStatus = data.payload.data.userCallStatusCallback;
            if (userCallStatus === 'IN_PROGRESS') {
                store_1.GlobalStore.currentCallInformation = {
                    ...store_1.GlobalStore.currentCallInformation,
                    callAnswered: true,
                    canInitiateNewTransfer: true,
                    userCallStatus,
                };
                const joCallScreen = document.getElementById('jo-call-screen');
                if (joCallScreen !== null) {
                    joCallScreen.store.holdButtonEnabled = true;
                }
                closeOutgoingUserCallStatusSubscriptionIfNecessary();
            }
        }, {
            userCallSid,
        });
        store_1.GlobalStore.subscriptions = {
            ...store_1.GlobalStore.subscriptions,
            outgoingUserCallStatusSubscription: outgoingUserCallStatusSubscription ?? 'NOT_SET',
        };
    }
    catch (error) {
        (0, utilities_1.handleError)(`subscribeToOutgoingUserCallStatusCallback error: ${error}`);
    }
}
function closeOutgoingCallGlobalSubscriptionsIfNecessary() {
    closeOutgoingUserCallStatusSubscriptionIfNecessary();
    closeoutgoingCallInitiatedSubscriptionIfNecessary();
}
function closeOutgoingUserCallStatusSubscriptionIfNecessary() {
    if (store_1.GlobalStore.subscriptions.outgoingUserCallStatusSubscription !== 'NOT_SET') {
        store_1.GlobalStore.subscriptions.outgoingUserCallStatusSubscription.close();
        store_1.GlobalStore.subscriptions = {
            ...store_1.GlobalStore.subscriptions,
            outgoingUserCallStatusSubscription: 'NOT_SET',
        };
    }
}
function closeoutgoingCallInitiatedSubscriptionIfNecessary() {
    if (store_1.GlobalStore.subscriptions.outgoingCallInitiatedSubscription !== 'NOT_SET') {
        store_1.GlobalStore.subscriptions.outgoingCallInitiatedSubscription.close();
        store_1.GlobalStore.subscriptions = {
            ...store_1.GlobalStore.subscriptions,
            outgoingCallInitiatedSubscription: 'NOT_SET',
        };
    }
}
// TODO this is probably not the best check, and not in all cases will it tell you if an action item is truly new
// TODO as in it has not been used yet by a call...we might want to add some kind of flag to action items in the database
// TODO to get a better check, but in the scenario that we are using this, we think it will work fine for now
function getCallScreenModeForOutgoingCall(actionItem) {
    return actionItem === null ||
        actionItem === undefined ||
        actionItem.created_at === actionItem.updated_at
        ? 'OUTGOING_NEW_ACTION_ITEM'
        : 'OUTGOING_EXISTING_ACTION_ITEM';
}
async function handleDialPhoneNumberClickedOnJOContact(e, element, callback) {
    const { joContactState, phoneNumber } = e.detail;
    (0, utilities_1.assertIsSet)(joContactState.authenticatedUser, utilities_1.handleError, 'joContactState.authenticatedUser');
    (0, utilities_1.assertIsSet)(joContactState.contact, utilities_1.handleError, 'joContactState.contact');
    (0, utilities_1.assertIsSet)(joContactState.contact.company, utilities_1.handleError, 'joContactState.contact.company');
    if (element['store']['loading'] !== undefined) {
        element.store.loading = true;
    }
    await callback();
    startOutgoingCall(joContactState.actionItemId === 'NOT_SET' ? undefined : joContactState.actionItemId, joContactState.contact.id, joContactState.contact.company.id, phoneNumber, joContactState.authenticatedUser.id);
    // this wait is to prevent element from ending its load animation before navigating to the call screen
    await (0, utilities_1.wait)(3000);
    if (element['store']['loading'] !== undefined) {
        element.store.loading = false;
    }
}
exports.handleDialPhoneNumberClickedOnJOContact = handleDialPhoneNumberClickedOnJOContact;
async function fetchWorkStatus(userId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($userId: Int!) {
                    getUsers(id: $userId) {
                        id
                        work_status
                    }
                }
            `, {
            userId,
        });
        return gqlResult.data.getUsers.work_status;
    }
    catch (error) {
        const errorMessage = `twilio.ts > fetchWorkStatus error: ${error}`;
        (0, utilities_1.handleError)(errorMessage);
        throw new Error(errorMessage);
    }
}
